<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <div>
      <p-form
        ref="form"
        @submit="onSubmit"
        v-slot="{ invalid }"
      >
        <!-- Título -->
        <div class="c-row">
          <div class="c-col">
            <div class="c-text-subtitle">
              Crie uma conta no Estratégia
            </div>
          </div>
        </div>

        <template v-if="showSocialLogin">
          <!-- Social Login Título -->
          <div class="c-row ui-mb-2">
            <div class="c-col  ">
              <span class="c-text-b2 c-text-gray-300">Se preferir, crie sua conta com:</span>
            </div>
          </div>

          <!-- Social Login Botões -->
          <div class="c-row ui-mb-8">
            <div class="c-col">
              <separator />
            </div>

            <div class="c-d-flex">
              <social-login-button provider="facebook" />
              <social-login-button provider="google" />
              <social-login-button provider="apple" />
            </div>

            <div class="c-col">
              <separator />
            </div>
          </div>
        </template>

        <!-- Label Preenchimento obrigatório -->
        <div class="c-row ui-mb-4">
          <div class="c-col">
            <div class="c-text-b2 c-text-gray-300">
              *Preenchimento obrigatório
            </div>
          </div>
        </div>

        <!-- Nome completo -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|full_name"
            name="Nome Completo"
            v-slot="{ errors }"
          >
            <p-input
              data-cy="nameField"
              key="nameField"
              label="Nome completo*"
              name="name"
              v-model.trim="form.name"
              :error-message="errors[0]"
              :mask="fullNameRegex"
            />
          </validation-provider>
        </div>

        <!-- Email -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col"
            rules="required|email"
            name="Email"
            vid="email"
            v-slot="{ errors }"
          >
            <p-input
              data-cy="emailField"
              key="emailField"
              label="Email*"
              name="email"
              v-model.trim="form.email"
              :error-message="errors[0]"
            />
          </validation-provider>
        </div>

        <!-- Senha / Confirmar senha -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col-2 sm:c-col"
            rules="required|min:8"
            name="Senha"
            vid="password"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Senha é obrigatória',
              min: 'A senha é muito curta'
            }"
          >
            <input-password
              data-cy="passwordField"
              key="passwordField"
              label="Senha*"
              name="password"
              v-model.trim="form.password"
              :error-message="errors[0]"
              icon-info="info"
            />
          </validation-provider>

          <validation-provider
            class="c-col-2 sm:c-col"
            rules="required|confirmed:password"
            name="Confirmação de senha"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Confirmação de senha é obrigatória',
              confirmed: 'Senhas não são iguais'
            }"
          >
            <input-password
              data-cy="confirmPasswordField"
              key="confirmPasswordField"
              label="Confirmar senha*"
              name="confirm-password"
              v-model.trim="form.confirmPassword"
              :error-message="errors[0]"
            />
          </validation-provider>
        </div>

        <div
          v-if="errors.length > 0"
          class="q-mt-md text-negative"
        >
          <q-list>
            <div
              v-for="(error, index) in errors"
              :key="index"
            >
              * {{ error }}
            </div>
          </q-list>
        </div>

        <Separator />

        <!-- Checkbox Política de Privacidade -->
        <div class="c-row ui-mb-4">
          <div class="c-col">
            <div class="c-text-b2 c-text-gray">
              <c-checkbox v-model="checkbox">
                Li, entendi e concordo com a
                <a
                  @click.stop
                  :href="politicaDePrivacidadeHref"
                  target="_blank"
                >
                  <b>
                    <u>Política de privacidade</u>
                  </b>
                </a>
              </c-checkbox>
            </div>
          </div>
        </div>

        <Separator />

        <!-- Botão Continuar -->
        <div class="c-row">
          <div class="c-col sm:c-col-1/2">
            <c-button
              type="submit"
              :disabled="invalid || !checkbox"
              size="lg"
              :loading="isFormSending"
              class="ui-w-full"
            >
              Continuar
              <icon
                name="next"
                class="c-icon--right"
              />
            </c-button>
          </div>
        </div>
      </p-form>

      <Separator />

      <!-- Texto Já tem conta - Faça login -->
      <div class="c-text-b2 c-text-gray">
        <span class="ui-font-semibold">
          Já tem usuário?
        </span>

        <router-link
          :to="{ name: 'login', query: { ...$route.query } }"
          data-cy="doLoginLink"
        >
          <u class="ui-font-bold">Faça o login</u>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, CCheckbox, CButton } from '@estrategiahq/coruja-web-ui';
import { User } from '@/models/user';
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import Separator from '@/components/separator';
import InputPassword from '@/components/input-password';
import SocialLoginButton from '@/components/p-social-login-button';
import illustration from '@/assets/images/register-illustration.svg';
import { politicaDePrivacidadeHref } from '@/consts';
import { userAlreadyExistsError } from '@/utils/errors';
import { ValidationProvider } from 'vee-validate';
import { FULL_NAME_REGEX } from '@/utils/regex';

export default {
  components: {
    SocialLoginButton,
    Icon,
    CCheckbox,
    CButton,
    PInput,
    PForm,
    InputPassword,
    Separator,
    ValidationProvider,
  },
  data() {
    return {
      illustration,
      politicaDePrivacidadeHref,
      form: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      errors: [],
      checkbox: false,
      isFormSending: false,
      showSocialLogin: false,
      fullNameRegex: FULL_NAME_REGEX,
    };
  },
  mounted() {
    const hideSocial = this.getBoolParam('hide-social');
    if (!hideSocial) {
      this.$socialLogin.someActive().then((someActive) => {
        this.showSocialLogin = someActive;
      });
    }

    const hideSignup = this.getBoolParam('hide-signup');
    if (hideSignup) {
      this.$router.push({
        name: 'login',
        query: { ...this.$route.query },
      });
    }
  },
  methods: {
    getBoolParam(name) {
      try {
        const val = JSON.parse(this.$route.query[name]);
        return !!val;
      } catch (_) {
        return false;
      }
    },
    async onSubmit() {
      this.isFormSending = true;
      try {
        const userData = new User({
          name: this.form.name,
          email: this.form.email,
        });

        await this.$services.accounts.createUser(userData, this.form.password);

        this.$services.events.notifyManualRegistration();

        this.$router.push({
          name: 'cadastro-confirme-seu-email',
          query: {
            ...this.$route.query,
            email: this.form.email,
          },
        });
      } catch (error) {
        this.errors = error.response?.data?.errors || [];

        this.$toast.show({
          type: 'error',
          text: 'Erro ao realizar o cadastro',
        });

        if (error === userAlreadyExistsError) {
          this.$refs.form.setErrors({
            email: ['Email já cadastrado'],
          });
        }
      } finally {
        this.isFormSending = false;
      }
    },
  },
};
</script>

<style>
.text-negative {
  color: var(--c-color-red-500);
}
</style>
