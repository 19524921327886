const COOKIE_UTM_KEY = 'utm:login';

export function extractUtmFromCookies() {
  const utm = document?.cookie?.split('; ')?.find(s => s.startsWith(COOKIE_UTM_KEY))?.split('=')?.pop();
  if (!utm) {
    return null;
  }

  try {
    const json = JSON.parse(decodeURIComponent(utm));
    return json;
  } catch (e) {
    return null;
  }
}
