<template>
  <p-input
    ref="pInput"
    v-bind="$attrs"
    v-on="$listeners"
    @handle-icon-click="showPassword = !showPassword"
    :icon="currentIcon"
    :type="showPassword ? 'text': 'password'"
  />
</template>

<script>
import PInput from '@/components/p-input';

export default {
  name: 'InputPassword',
  components: {
    PInput,
  },
  data() {
    return {
      showPassword: this.defaultVisible,
    };
  },
  props: {
    defaultVisible: { type: Boolean, default: false },
  },
  computed: {
    currentIcon() {
      return this.showPassword ? 'eyeInvisible' : 'eye';
    },
  },
  methods: {
    validate() {
      this.$refs.pInput.validate();
    },
  },
};
</script>
